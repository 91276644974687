// $mbsc-material-accent: #ffffff;
$variable: #6b6b6b;
// $mbsc-material-background: #ffffff;
// $mbsc-material-text: #ffffff;
$mbsc-material-dark-accent: $variable;
$mbsc-material-dark-background: #1e1f29;
// $mbsc-material-dark-text: #ffffff;

// $mbsc-background-dark: #ffffff;

// $mbsc-ios-primary: #3f97f6;
// $mbsc-ios-secondary: #90979e;
// $mbsc-ios-success: #43be5f;
// $mbsc-ios-danger: #f5504e;
// $mbsc-ios-warning: #f8b042;
// $mbsc-ios-info: #5bb7c5;
// $mbsc-ios-light: #ffffff;
// $mbsc-ios-dark: #47494a;

// Global calendar colors
$mbsc-calendar-background-light: #eaeaea;
$mbsc-calendar-background-dark: #1e1f29;
// $mbsc-calendar-text-light: #ffffff;
// $mbsc-calendar-text-dark: #ffffff;
$mbsc-calendar-accent-light: $variable;
$mbsc-calendar-accent-dark: $variable;
// $mbsc-calendar-border-light: #ffffff;
// $mbsc-calendar-border-dark: #ffffff;
// $mbsc-calendar-mark-light: #ffffff;
// $mbsc-calendar-mark-dark: #ffffff;
// $mbsc-form-background-dark: #1e1f29;

$mbsc-frame-background-dark: #ffffff;
$mbsc-frame-overlay-dark: #ffffff;
$mbsc-material-dark-frame-background: #ffffff;
$mbsc-material-dark-frame-text: #ffffff;
$mbsc-material-dark-frame-accent: #ffffff;
$mbsc-material-dark-frame-overlay: #ffffff;
$picker-background: #ffffff;
@import "~@mobiscroll/react/dist/css/mobiscroll.scss";

// light
.mbsc-material {
  .mbsc-datepicker {
    .mbsc-calendar-slide {
      background: $mbsc-calendar-background-light;
    }
  }
  .mbsc-popup-body {
    background: $mbsc-calendar-background-light;
  }

  .mbsc-selected {
    .mbsc-calendar-cell-text {
      color: #ffffff;
    }
  }
  .mbsc-textfield-box {
    .mbsc-select {
      background: $mbsc-calendar-background-light;
    }
  }
  .mbsc-textfield-inner-box {
    background: $mbsc-calendar-background-light;
  }
  a {
    color: inherit;
  }
  .mbsc-material.mbsc-label-box-floating.mbsc-ltr,
  .mbsc-material.mbsc-label-outline-floating.mbsc-ltr {
    left: 0.5em;
  }
}

// Dark
.mbsc-material-dark {
  .mbsc-datepicker {
    .mbsc-calendar-slide {
      background: $mbsc-calendar-background-dark;
    }
  }
  .mbsc-popup-body {
    background: $mbsc-calendar-background-dark;
  }

  .mbsc-material-dark.mbsc-range-control-label.active {
    color: #ffffff;
  }

  .mbsc-material-dark.mbsc-range-control-value.active,
  .mbsc-material-dark.mbsc-range-control-label.active {
    color: #ffffff;
  }

  .mbsc-material-dark.mbsc-segmented-button.mbsc-button {
    color: #808080;
  }

  .mbsc-selected {
    .mbsc-calendar-cell-text {
      color: #ffffff;
    }
  }
  .mbsc-textfield-box {
    .mbsc-select {
      background: $mbsc-calendar-background-dark;
    }
  }
  .mbsc-textfield-inner-box {
    background: $mbsc-calendar-background-dark;
  }

  .mbsc-schedule-event-range,
  .mbsc-schedule-event-title,
  .mbsc-calendar-label-text,
  .mbsc-calendar-label,
  .mbsc-schedule-event-inner {
    color: #ffffff;
  }
  a {
    color: inherit;
  }

  .mbsc-material.mbsc-label-box-floating.mbsc-ltr,
  .mbsc-material.mbsc-label-outline-floating.mbsc-ltr {
    left: 0.5em;
  }
}
